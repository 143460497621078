<template>
  <div class="now-chat-box">
    <heads
      :hideInput="hideInput"
      page="nowChat"
      @onClickRight="onClickRight"
      left="/home"
    ></heads>
    <chat-content
      :list="nowChatList"
      :transferData='transferData'
      @sendText="sendText"
      :chat_model="chat_model"
      ref="chatContent"
    ></chat-content>
    <chat-input @sendText="sendText"></chat-input>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Header from "@/components/Header";
import ChatInput from "@/components/ChatInput";
import ChatContent from "./Components/ChatContent";
import { agentDialog } from "@/fetch/api/chat.js";
export default {
  name: "nowchat",
  components: {
    heads: Header,
    ChatInput,
    ChatContent,
  },
  data() {
    return {
      broker_id: localStorage.getItem("broker_id"),
      from: this.$route.params.type,
      conversation_uuid: "",
      chat_model: 0,
      hebaoStatus: 0,
      hideInput: this.$route.query.underwriting == 1 ? false : true,
      transferData: false,
    };
  },
  computed: {
    ...mapState({
      nowChatList: (state) => state.chat.nowChatList,
    }),
  },
  methods: {
    ...mapMutations(["SET_CHAT_LIST"]),
    sendText(text, option = {}) {
      if (text == "") return;
      if (text == "核保") {
        this.$refs.chatContent.flag = true;
        this.chat_model = 1;
        this.hideInput = false;
        localStorage.setItem("hideInput", false);
        let question = {
          broker_id: this.broker_id,
          content: text.indexOf("<br>") > -1 ? text.replace(/<br>/g, "") : text,
          first_conversation: "0", //0非首次进入，1首次进入
          // conversation_uuid: this.conversation_uuid,//首次进入无id
          speaker: 1,
          is_collected: "0",
          online_data_id: null,
          hebao_answer: option.hebao_answer || {},
          reset_disease: option.reset_disease || 0,
          hebao: this.hebaoStatus,
          diseaseCode: option.diseaseCode || {},
        };
        this.SET_CHAT_LIST({ type: "add", data: question });
        agentDialog(question).then((res) => {
          if (res.code == 200) {
            this.hebaoStatus = res.result.content.hebao;
            this.SET_CHAT_LIST({ type: "add", data: res.result });
          } else if (res.code == 403) {
            this.$toast(`${res.error}`);
          }
        });
        return false;
      }
      let list = this.nowChatList[this.nowChatList.length - 1];
      if (
        list &&
        list.content &&
        list.content.hebao_answer &&
        list.content.hebao == 1
      ) {
        let answerId = Object.values(list.content.hebao_answer).map(
          (item) => item.answerId
        );
        if (
          option.hebao_answer &&
          answerId.indexOf(option.hebao_answer.answerId) > -1
        ) {
          let question = {
            broker_id: this.broker_id,
            content:
              text.indexOf("<br>") > -1 ? text.replace(/<br>/g, "") : text,
            first_conversation: "0", //0非首次进入，1首次进入
            // conversation_uuid: this.conversation_uuid,//首次进入无id
            speaker: 1,
            is_collected: "0",
            online_data_id: null,
            hebao_answer: option.hebao_answer || {},
            reset_disease: option.reset_disease || 0,
            hebao: this.hebaoStatus,
            diseaseCode: option.diseaseCode || {},
          };
          this.SET_CHAT_LIST({ type: "add", data: question });
          agentDialog(question).then((res) => {
            if (res.code == 200) {
              this.hebaoStatus = res.result.content.hebao;
              if (
                res.result.content &&
                res.result.content.answer.indexOf("已经努力查过了") > -1
              ) {
                this.transferData = true;
              }
              this.SET_CHAT_LIST({ type: "add", data: res.result });
            } else if (res.code == 403) {
              this.$toast(`${res.error}`);
            }
          });
        }
        return false;
      }
      let question = {
        broker_id: this.broker_id,
        content: text.indexOf("<br>") > -1 ? text.replace(/<br>/g, "") : text,
        first_conversation: "0", //0非首次进入，1首次进入
        // conversation_uuid: this.conversation_uuid,//首次进入无id
        speaker: 1,
        is_collected: "0",
        online_data_id: null,
        hebao_answer: option.hebao_answer || {},
        reset_disease: option.reset_disease || 0,
        hebao: this.hebaoStatus,
        diseaseCode: option.diseaseCode || {},
      };
      this.SET_CHAT_LIST({ type: "add", data: question });
      agentDialog(question).then((res) => {
        if (res.code == 200) {
          this.hebaoStatus = res.result.content.hebao;
          this.SET_CHAT_LIST({
            type: "add",
            data: Object.assign({ assess: "" }, res.result),
          });
        } else if (res.code == 403) {
          this.$toast(`${res.error}`);
        }
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          let container = document.getElementById("chatView");
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        }, 0);
      });
    },
    onClickRight() {
      this.$router.push({
        path: "/chat/history",
        query: {
          hideHistoryText: 1,
        },
      });
    },
    changeNowChatList(data) {
      this.SET_CHAT_LIST({ type: "upData", data: data });
    },
    // dialogFirst() {
    //   let question = {
    //     broker_id: this.broker_id,
    //     content: 'first_conversation',
    //     first_conversation: '1', //0非首次进入，1首次进入
    //     speaker: 1,
    //   }
    //   agentDialog(question).then(res=>{
    //     if(res.code == 200){
    //       this.conversation_uuid = res.result.conversation_uuid
    //       // this.chatList.push(res.result)
    //       this.SET_CHAT_LIST({type:'add',data:res.result})
    //     }
    //   })
    // },
  },
  updated() {
    this.scrollToBottom();
  },
  created() {
    this.scrollToBottom();
    // this.dialogFirst();
  },
};
</script>

<style lang="scss" scoped>
</style>
